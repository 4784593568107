@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import '~antd/dist/antd.css';

:root {
  --text-color: #001529;
  --twitter: #1da1f2;
  --linkedin: #0077b5;
  --github: #333333;
  --website: #1890ff;
  --gmail: #db4437;
  --white: #ffffff;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Layout CSS */
.ant-layout {
  background: var(--white);
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.ant-layout-header,
.ant-layout-footer {
  text-align: center;
  background: var(--text-color);
  color: var(--white);
}

/* Header CSS */
.header_space {
  width: 100%;
  justify-content: space-between;
  align-items: inherit;
}
.header_space_brand {
  padding: 0px;
  color: var(--white);
}
.header_space_brand:hover {
  color: var(--white);
}

/* Social Card CSS */
.social_card {
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  border-radius: 4px;
  background-color: bisque;
  color: var(--text-color);
}

.social_card_space {
  width: 100%;
}
.social_image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.social_button {
  padding: 0px;
}
.social_button.twitter {
  color: var(--twitter);
}
.social_button.linkedin {
  color: var(--linkedin);
}
.social_button.github {
  color: var(--github);
}
.social_button.website {
  color: var(--website);
}
.social_button.gmail {
  color: var(--gmail);
}

/* Profile Page CSS */
.profile_page_card {
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  border-radius: 4px;
}

.profile_save_btn {
  display: block;
  margin-left: auto;
}

.login_submit_btn {
  display: block;
  width: 100%;
}

.alert_error {
  margin-bottom: 8px;
}

.form_help_text {
  text-align: center;
}

/* for side bar */

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  /* background-color: #001529; */
  color: white;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.sideMenuTogelBtn {
  padding: 2px;
  border: none;
}

.siderComp {
  height: 100vh;
  /* width: 30vw; */
  /* width: 300px !important; */
  /* max-width: 300px !important; */
  max-height: 100vh;
  overflow-y: auto;
}

.MenuComp {
  /* height: 100vh; */
  /* width: fit-content; */

  /* overflow: auto; */
}

.mainContainer {
  /* display: flex;
  justify-content: stretch; */
}

.hiddenColumn {
  display: none;
}

/* for side bar */

.FilterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 10px 10px;
  padding: 10px 20px;
}

/* table filter styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tableSearchSection {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #ddd; */
  justify-content: center;
  /* padding: 20px; */
  margin-left: 10%;
  width: 80%;
  height: 20vh;
}

.tableSearchSection label {
  margin-right: 10px;
}

.tableSearchSection input[type='text'] {
  /* border: 1px solid #ccc; */

  padding: 5px;
  border-radius: 5px 0 0 5px;
}

.tableSearchSection button {
  /* padding: 5px 10px; */
  /* width: 20%;
  height: 40px; */
  padding: 5px 10px;
  cursor: pointer;
  background: #0b7dda;
  color: white;
  font-size: 15px;
  border: 1px solid lightgrey;
  border-radius: 0 5px 5px 0;
  border-left: none;
  text-align: center;
  cursor: pointer;
  height: auto;
  margin: 5px;
}

.filterScopeSelect {
  padding: 5px 10px;
  font-size: 15px;
  /* border: 1px solid lightgrey; */
  /* border-radius: 0 5px 5px 0; */

  width: 200px;
  margin: 5px;
}

.filterScopeSelect option {
  /* padding: ; */
}

.address-detail {
  white-space: pre-line;
}

.ActionColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ActionColumn > span {
  margin-right: 4px;
}

form.searchBarr {
  width: 100%;
  position: relative;
  display: flex;
}

form.searchBarr input[type='text'] {
  padding: 5px;
  border: 1px solid lightgrey;
  border-right: none;
  border-radius: 5px 0 0 5px;
  height: 40px;
  outline: none;
  /* float: left; */
  /* font-size: 15px; */
  width: 80%;
}

form.searchBarr button {
  /* float: left; */
  width: 20%;
  height: 40px;
  padding: 5px;
  background: #0b7dda;
  color: white;
  font-size: 15px;
  border: 1px solid lightgrey;
  border-radius: 0 5px 5px 0;
  border-left: none;
  text-align: center;
  cursor: pointer;
}

form.searchBarr button:hover {
  /* background: #0b7dda; */
  background: #2196f3;
}

.launchDetails {
  padding: 10px;
  margin-top: 20px;
  background: rgb(233, 231, 231);
  border: 0.5px solid lightgrey;
  border-radius: 5px 5px 5px 5px;
}

.ant-table-column-sorters {
  display: inline-flex;
  align-items: center;
}

.Address {
  border: 1px solid lightgray;
  padding: 10px;
  margin: 10px 0px 10px 0px;
}

.addNewMasterNSelect {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-evenly;
}

.addNewMasterNSelectInside {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  margin-right: 10px;
}

.addIconForNewEntity {
  /* align-self: flex-end; */
  flex-grow: 1;
}

.SelectEntity {
  flex-grow: 3;
}

/*
----------
Row Color Class
----------
*/

.table-row-confirmed {
  /* background-color: rgba(228, 246, 232, 0.898); */
  color: rgb(214, 221, 16);
}

.table-row-complete {
  /* background-color: rgb(190, 192, 188); */
  color: rgb(118, 230, 6);
}

.appversion {
  margin: 5px;
  opacity: 70%;
}

.genBuyPlan {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  row-gap: 10px;
  column-gap: 20px;
}

.genBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.infoPage_actionButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.ant-checkbox-wrapper {
  margin-right: 5px;
}
.antd-column-large {
  width: 30rem !important;
  max-width: 30rem !important;
}
